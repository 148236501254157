<template>
  <base-form-item :field="field">
    <el-select
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : false"
        :collapse-tags="field.meta && field.meta.collapseTags ? field.meta.collapseTags : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :filterable="typeof field.meta.filterable === 'undefined' ? true : field.meta && !!field.meta.filterable"
        :multiple="field.meta && field.meta.multiple ? field.meta.multiple : false"
        :multiple-limit="field.meta && field.meta.multipleLimit ? field.meta.multipleLimit : 0"
        :name="field.dbName"
        :no-data-text="field.meta && field.meta.noDataText ? field.meta.noDataText : 'No data'"
        :no-match-text="field.meta && field.meta.noMatchText ? field.meta.noMatchText : 'No matching data'"
        :placeholder="field.meta && field.meta.placeholder ? field.meta.placeholder : 'Select'"
        :reserve-keyword="field.meta && field.meta.reserveKeyword ? field.meta.reserveKeyword : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        :value="value"
        @change="onChange"
        v-model="value"
    >
      <el-option
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in dictionaries && dictionaries[field.dictionary] ? dictionaries[field.dictionary] : []"
      >
        <div
            class="el-select--matrixOption"
            v-if="meta.type === 'matrix'"
        >
          <div class="d-flex justify-content-between">
            <div><span style="font-weight: bold">{{ item.name }}</span></div>
            <div><span style="color: #8492a6; font-size: 13px">{{ item.procedure_type }}</span></div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="el-select--matrixOptionNoWrap">
                  <span
                      :key="disease.id + '' + index"
                      v-for="(disease, index) in item.diseases"
                  >
                    {{ disease.name }}
                  </span>
            </div>
          </div>
        </div>
      </el-option>
    </el-select>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import functions from '../../mixin/functions';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm, functions],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},

  created() {
    if (this.value) {
      this.value = parseInt(this.value);
    }
    if (this.meta && this.meta.multiple) {
      if (!this.value) {
        this.value = [];
      }
    }
  },
  mounted() {
    if (this.mode === 'create' && !this.value) {
      const defaultValue = this.dictionaries[this.field.dictionary].find(item => item.is_default === 1);
      if (defaultValue) {
        this.value = defaultValue.id;
      }
    }
  },
  methods: {
    onChange(val) {
      if (this.meta && this.meta.onChange) {
        this[this.meta.onChange](val);
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-select--matrix {
  .el-select-dropdown__item {
    height: auto !important;
  }
}

.el-select--matrixOptionNoWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
  padding-right: 40px;
}
</style>
